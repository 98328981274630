<template>
  <div class="dropdown-menu">
    <div class="dropdown-menu__links">
      <div class="dropdown-menu__navigation">
        <div
          v-for="(section, index) of navigationTabs"
          :key="index"
          class="dropdown-menu__navigation_list"
        >
          <button
            v-for="(tab, tabIndex) of section"
            :key="`${index}-${tabIndex}`"
            :class="['m-btn', tab.colorClass, { 'active': activeTab === tab.type }]"
            @click="activeTab = tab.type"
          >
            <span>{{ tab.label }}</span>
            <img
              v-if="activeTab === tab.type"
              src="/v2/header/arrow.svg"
              alt="arrow"
            >
          </button>
        </div>
      </div>

      <div
        v-if="activeTab"
        class="dropdown-menu__content"
      >
        <transition
          name="fade"
          mode="out-in"
        >
          <app-header-dropdowns
            :key="activeTab"
            :type="activeTab"
            @close-menu="$emit('close-menu')"
          />
        </transition>
      </div>
    </div>
    <div class="dropdown-menu__form">
      <h5>мы поможем подобрать подходящее обучение</h5>

      <div class="dropdown-menu__form_fields">
        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="dropdown-menu__form_field"
          placeholder="как вас зовут?"
          round-border
        />

        <form-phone-field
          ref="phoneField"
          class="dropdown-menu__form_field dropdown-menu__form_field--phone"
          :errors-list="submitError"
          :error="validForm === false"
          round-border
          placeholder="ваш номер телефона"
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="dropdown-menu__form_field"
          :submit-error="submitError"
          round-border
          placeholder="ваша почта"
          @clearSubmitError="clearSubmitError"
        />
      </div>

      <!-- eslint-disable -->
      <p class="dropdown-menu__form_links">
          нажимая на кнопку, вы соглашаетесь
          с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >условиями обработки данных</nuxt-link>
          в&nbsp;соответствии
          с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >политикой конфиденциальности</nuxt-link>
        </p>
      <!-- eslint-enable -->

      <button
        class="m-btn"
        @click="submit"
      >
        получить консультацию
      </button>
    </div>
  </div>
</template>

<script>
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import AppHeaderDropdowns from './AppHeaderDropdowns.vue';

import { PAGE_TYPES } from './data';

const sendLeadMixin = sendLeadMixinConstructor({
  needName: true,
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
  needToSendComments: true,
});

export default {
  name: 'DropdownMenu',
  components: {
    AppHeaderDropdowns,
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  data: () => ({
    activeTab: null,
    navigationTabs: [
      [
        { type: PAGE_TYPES.homeSchool, label: 'домашняя школа', colorClass: 'orange' },
        { type: PAGE_TYPES.oge, label: 'подготовка к ОГЭ', colorClass: 'blue' },
        { type: PAGE_TYPES.ege, label: 'подготовка к ЕГЭ', colorClass: 'blue' },
        { type: PAGE_TYPES.tutors, label: 'репетиторы', colorClass: 'violet' },
        { type: PAGE_TYPES.skills, label: 'развитие навыков', colorClass: 'green' },
        { type: PAGE_TYPES.it, label: 'it-курсы для детей', colorClass: 'light-green' },
      ],
      [
        { type: PAGE_TYPES.class10, label: '10-11 классы', colorClass: 'violet' },
        { type: PAGE_TYPES.class8, label: '8-9 классы', colorClass: 'violet' },
        { type: PAGE_TYPES.class5, label: '5-7 класс', colorClass: 'violet' },
        { type: PAGE_TYPES.class1, label: '1-4 класс', colorClass: 'violet' },
        { type: PAGE_TYPES.kids, label: 'дошкольники', colorClass: 'violet' },
        { type: PAGE_TYPES.adult, label: 'взрослым', colorClass: 'red' },
      ],
    ],
  }),

  computed: {
    titleToSend() {
      return 'меню';
    },
    commentsToSend() {
      if (!this.activeTab) return '';
      return this.navigationTabs.flat().find(({ type }) => type === this.activeTab).label;
    },
  },

  created() {
    const pageName = this.$route.name;

    switch (pageName) {
      case 'oge':
      case 'oge-subpage':
        this.activeTab = PAGE_TYPES.oge;
        break;

      case 'ege':
      case 'ege-subpage':
        this.activeTab = PAGE_TYPES.ege;
        break;

      case 'repetitor':
      case 'repetitor-anglijskij-yazyk':
      case 'repetitor-matematika':
      case 'repetitor-biologiya':
      case 'repetitor-fizika':
      case 'repetitor-himiya':
      case 'repetitor-informatika':
      case 'repetitor-istoriya':
      case 'repetitor-obshchestvoznanie':
      case 'repetitor-russkij-yazyk':
      case 'catalog-repetitor':
      case 'catalog-repetitor-first':
      case 'catalog-repetitor-first-second':
      case 'catalog-repetitor-first-second-third':
        this.activeTab = PAGE_TYPES.tutors;
        break;

      case 'soft-skills':
        this.activeTab = PAGE_TYPES.skills;
        break;

      default:
        this.activeTab = PAGE_TYPES.homeSchool;
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: fixed;
  left: 0;
  top: calc(var(--scale) * 70);
  z-index: 9;

  padding: calc(var(--scale) * 20) calc(var(--scale) * 40) calc(var(--scale) * 40);
  display: flex;
  gap: calc(var(--scale) * 20);
  width: 100%;
  max-height: calc(100vh - (var(--scale) * 100));
  overflow-y: auto;

  background-color: #ECEDF0;
  box-shadow:
    calc(var(--scale) * 10) calc(var(--scale) * 10) calc(var(--scale) * 50) rgba(#071539, 0.1),
    calc(var(--scale) * 4) calc(var(--scale) * 4) calc(var(--scale) * 20) rgba(#071539, 0.05);
  border-radius: 0 0 calc(var(--scale) * 60) calc(var(--scale) * 60);

  * {
    font-family: "Onest", sans-serif;
  }

  &__links {
    width: 100%;
    display: flex;
    background: #FFFFFF;
    border-radius: calc(var(--scale) * 40);
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40);
  }

  &__navigation {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 30);
    width: calc(var(--scale) * 210);
    padding-right: calc(var(--scale) * 20);
    border-right: 1px solid #DADCDB;

    &_list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: calc(var(--scale) * 4);

      button {
        display: flex;
        align-items: center;
        gap: calc(var(--scale) * 8);
        padding: calc(var(--scale) * 10) calc(var(--scale) * 20);

        background-color: #FFFFFF;
        border: 1px solid #CACACB;
        box-shadow: inset 0 calc(var(--scale) * 5) calc(var(--scale) * 20) rgba(#ffffff, 0.15);
        backdrop-filter: blur(5px);
        border-radius: calc(var(--scale) * 24);
        transition: 200ms;

        span {
          transition: 200ms;
          font-style: normal;
          font-weight: 500;
          font-size: calc(var(--scale) * 16);
          line-height: 130%;
          color: #A7ABAC;
          white-space: nowrap;
        }
      }

      button.orange {
        &:hover span {
          color: #FF5319;
        }

        &.active {
          background-color: #FFF0D1;
          border-color: #FFF0D1;

          span {
            color: #FF5319;
          }

          img {
            filter: invert(59%) sepia(58%) saturate(7143%) hue-rotate(349deg) brightness(100%) contrast(102%);
          }
        }
      }

      button.blue {
        &:hover span {
          color: #0A4183;
        }

        &.active {
          background-color: #CEF9FF;
          border-color: #CEF9FF;

          span {
            color: #0A4183;
          }

          img {
            filter: invert(13%) sepia(53%) saturate(4403%) hue-rotate(204deg) brightness(101%) contrast(92%);
          }
        }
      }

      button.violet {
        &:hover span {
          color: #5237E6;
        }

        &.active {
          background-color: #E6CEFF;
          border-color: #E6CEFF;

          span {
            color: #5237E6;
          }

          img {
            filter: invert(17%) sepia(97%) saturate(3477%) hue-rotate(248deg) brightness(92%) contrast(96%);
          }
        }
      }

      button.green {
        &:hover span {
          color: #01410D;
        }

        &.active {
          background-color: #D1FAD8;
          border-color: #D1FAD8;

          span {
            color: #01410D;
          }

          img {
            filter: invert(13%) sepia(22%) saturate(6598%) hue-rotate(120deg) brightness(98%) contrast(99%);
          }
        }
      }

      button.light-green {
        &:hover span {
          color: #01412E;
        }

        &.active {
          background-color: #E3FFF7;
          border-color: #E3FFF7;

          span {
            color: #01412E;
          }

          img {
            filter: invert(18%) sepia(38%) saturate(1564%) hue-rotate(122deg) brightness(93%) contrast(99%);
          }
        }
      }

      button.red {
        &:hover span {
          color: #8D1717;
        }

        &.active {
          background-color: #FFCDCF;
          border-color: #FFCDCF;

          span {
            color: #8D1717;
          }

          img {
            filter: invert(23%) sepia(6%) saturate(7270%) hue-rotate(317deg) brightness(106%) contrast(126%);
          }
        }
      }
    }
  }

  &__content {
    padding-left: calc(var(--scale) * 40);
  }

  &__form {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(var(--scale) * 445);
    background-color: #F6F7FA;
    border-radius: calc(var(--scale) * 40);
    padding: calc(var(--scale) * 24) calc(var(--scale) * 20) calc(var(--scale) * 20);

    h5 {
      margin-bottom: calc(var(--scale) * 30);
      padding-inline: calc(var(--scale) * 10);

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #02120F;
    }

    &_fields {
      margin-bottom: calc(var(--scale) * 20);
      display: flex;
      flex-direction: column;
      gap: calc(var(--scale) * 8);
      width: 100%;
    }

    &_field ::v-deep(input),
    &_field ::v-deep(.phone-field__wrap) {
      width: 100%;
      height: calc(var(--scale) * 72);
      background-color: #FFFFFF;
      border: 1px solid rgba(167, 171, 172, 0.4);
    }

    &_links {
      padding-inline: calc(var(--scale) * 5);
      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      color: #A7ABAC;

      a {
        color: #5237E6;
        text-decoration: underline;
      }
    }

    .m-btn {
      width: 100%;
      margin-top: auto;
      padding: calc(var(--scale) * 19) calc(var(--scale) * 10) calc(var(--scale) * 22);
      background-color: #FF5319;
      border-radius: calc(var(--scale) * 24);

      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
