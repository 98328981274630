<template>
  <div :class="`app-header__dropdowns app-header__dropdowns--${type}`">
    <div
      v-for="(group, groupName) in links"
      :key="groupName"
      :class="`dropdown dropdown--${groupName}`"
    >
      <p class="dropdown__title">
        {{ group.title }}
      </p>
      <div class="dropdown__links">
        <template v-for="(link, idx) in group.items">
          <nuxt-link
            v-if="link.to"
            :key="`link-${idx}`"
            :to="link.to"
          >
            <span v-html="link.pretext" />
            <b v-html="link.name" />
          </nuxt-link>
          <button
            v-else-if="link.modal"
            :key="`button-${idx}`"
            class="m-btn"
            @click="openModal(link.modal)"
          >
            <span v-html="link.pretext" />
            <b v-html="link.name" />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { PAGE_TYPES, LINKS } from './data';

export default {
  name: 'AppHeaderDropdowns',
  props: {
    type: {
      type: String,
      default: PAGE_TYPES.homeSchool,
    },
  },
  computed: {
    links() {
      return LINKS[this.type];
    },
  },
  methods: {
    ...mapActions('appHeaderModals', {
      storeOpenModal: 'openModal',
    }),
    openModal(type) {
      this.storeOpenModal(type);
      this.$emit('close-menu');
    },
  },
};
</script>

<style scoped lang="scss">

.app-header__dropdowns {
  display: grid;
  grid-template-columns: calc(var(--scale) * 285) calc(var(--scale) * 285) calc(var(--scale) * 285);
  grid-template-rows: repeat(2, auto);
  row-gap: calc(var(--scale) * 40);
  column-gap: calc(var(--scale) * 60);

  @include media-down($size-tablet) {
    grid-template-columns: repeat(2, calc(var(--scale) * 300));
    row-gap: calc(var(--scale) * 30);
  }

  @include media-down($size-mobile) {
    grid-template-columns: 100%;
  }

  * {
    font-family: "Onest", sans-serif;
  }
}

.dropdown {
  &__title {
    margin-bottom: calc(var(--scale) * 20);

    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 8);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--scale) * 4);

    a,
    button {
      padding-block: calc(var(--scale) * 7) calc(var(--scale) * 9);

      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #02120F;
      text-align: left;

      @include media-down($size-tablet) {
        padding-block: calc(var(--scale) * 5) calc(var(--scale) * 7);
        font-size: calc(var(--scale) * 18);
        letter-spacing: -0.02em;
      }

      span {
        text-decoration: underline;
      }
    }
  }
}

.app-header__dropdowns--home {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #FF5319;
  }

  .dropdown--school,
  .dropdown--age {
    grid-row: 1/3;

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--ege,
.app-header__dropdowns--oge {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #0A4183;
  }

  .dropdown--subject {
    @include media-down($size-tablet) {
      grid-row: 1/3;
    }

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--10-11,
.app-header__dropdowns--8-9,
.app-header__dropdowns--5-7,
.app-header__dropdowns--1-4,
.app-header__dropdowns--kids,
.app-header__dropdowns--tutors {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #5237E6;
  }
}

.app-header__dropdowns--tutors {
  grid-template-rows: repeat(3, auto);

  .dropdown--subject {
    grid-row: 1/4;

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }

  .dropdown--age {
    grid-row: 1/3;
    grid-column: 3/4;

    @include media-down($size-tablet) {
      grid-column: auto;
    }

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }

  .dropdown--free {
    margin-top: calc(-1 * var(--scale) * 140);
    grid-column: 3/4;

    @include media-down($size-tablet) {
      margin-top: 0;
      grid-column: auto;
    }

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--10-11,
.app-header__dropdowns--8-9 {
  .dropdown--courses {
    grid-row: 1/3;
    grid-column: 2/3;

    @include media-down($size-tablet) {
      grid-column: auto;
    }
  }

  .dropdown--free {
    margin-top: calc(-1 * var(--scale) * 100);

    @include media-down($size-tablet) {
      margin-top: 0;
    }
  }
}

.app-header__dropdowns--5-7 {
  .dropdown--school,
  .dropdown--courses {
    grid-row: 1/3;

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--skills {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #01410D;
  }

  .dropdown--courses,
  .dropdown--directions {
    grid-row: 1/3;

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--it {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #01412E;
  }

  .dropdown--programming {
    @include media-down($size-tablet) {
      grid-row: 1/3;
    }

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

.app-header__dropdowns--adult {
  .dropdown__title,
  a span,
  a:hover,
  button span,
  button:hover {
    color: #8D1717;
  }

  .dropdown--education {
    @include media-down($size-tablet) {
      grid-row: 1/3;
    }

    @include media-down($size-mobile) {
      grid-row: auto;
    }
  }
}

</style>
