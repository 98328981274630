<template>
  <div :class="['header-modal', getModalData.className]">
    <div class="header-modal__inner">
      <div class="header-modal__title">
        <h6 v-html="getModalData.title" />
        <p
          v-if="getModalData.cheap"
          class="header-modal__title_cheap"
        >
          {{ getModalData.cheap }}
        </p>
      </div>

      <p
        v-if="getModalData.description"
        class="header-modal__description"
        v-html="getModalData.description"
      />

      <div class="header-modal__fields">
        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          round-border
          placeholder="как вас зовут?"
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          round-border
          placeholder="ваш номер телефона"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input"
          round-border
          :submit-error="submitError"
          placeholder="ваша почта"
          @clearSubmitError="clearSubmitError"
        />
      </div>

      <!-- eslint-disable -->
      <p class="header-modal__law">
          нажимая на кнопку, вы соглашаетесь
          с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >условиями обработки данных</nuxt-link>
          в&nbsp;соответствии
          с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >политикой конфиденциальности</nuxt-link>
        </p>
      <!-- eslint-enable -->

      <button
        class="m-btn header-modal__btn"
        @click="getModalData.register ? signUp() : submit()"
      >
        {{ getModalData.button }}
      </button>
    </div>

    <button
      class="m-btn header-modal__close"
      @click="closeModal"
    >
      <img
        src="/v2/header/modal-close.svg"
        alt="close"
      >
    </button>

    <div
      class="header-modal__overlay"
      @click="closeModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import { getQuestionnairePath } from '@/utils/questionnairePath';

import { MODAL_TYPES } from './data';

const sendLeadMixin = sendLeadMixinConstructor({
  needName: true,
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
  needToSendComments: true,
});

export default {
  name: 'AppHeaderModal',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  props: {
    propLandCode: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapGetters('appHeaderModals', {
      currentModal: 'currentModal',
      currentModalComment: 'currentModalComment',
      currentPurchaseProductId: 'currentPurchaseProductId',
    }),
    /* eslint-disable brace-style */
    getModalData() {
      let modalData = {};
      if (this.currentModal === MODAL_TYPES.cost) {
        modalData = {
          title: 'узнайте стоимость обучения в нашей школе',
          description: 'сотрудник отдела заботы расскажет все&nbsp;подробности',
        };
      }
      else if (this.currentModal === MODAL_TYPES.certificate) {
        modalData = {
          title: 'получите московский аттестат гос. образца',
          description: 'сотрудник отдела заботы расскажет все&nbsp;подробности',
        };
      }
      else if (this.currentModal === MODAL_TYPES.freeLesson) {
        modalData = {
          title: 'запишитесь на&nbsp;<span>бесплатный</span> урок по любому предмету',
          description: 'сотрудник отдела заботы подберет удобное время',
          button: 'записаться на урок',
        };
      }
      else if (this.currentModal === MODAL_TYPES.freePrograming) {
        modalData = {
          title: 'запишитесь на&nbsp;<span>бесплатный</span> урок по программированию',
          description: 'сотрудник отдела заботы подберет удобное время',
          button: 'записаться на урок',
          className: 'green',
        };
      }
      else if (this.currentModal === MODAL_TYPES.discount) {
        modalData = {
          title: 'оставьте заявку и&nbsp;узнайте о&nbsp;текущих скидках и&nbsp;акциях',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и&nbsp;текущих предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.attachment) {
        modalData = {
          title: 'прикрепитесь к нашей школе в любое время',
          description: 'сотрудник отдела заботы расскажет все&nbsp;подробности',
        };
      }
      else if (this.currentModal === MODAL_TYPES.ogeCollege) {
        modalData = {
          title: 'поможем вам&nbsp;поступить в&nbsp;колледж мечты',
          className: 'blue',
        };
      }
      else if (this.currentModal === MODAL_TYPES.egeVuz) {
        modalData = {
          title: 'поможем вам&nbsp;поступить в&nbsp;университет&nbsp;мечты',
          className: 'blue',
        };
      }
      else if (this.currentModal === MODAL_TYPES.psychologist) {
        modalData = {
          title: 'оставьте заявку на&nbsp;запись к&nbsp;детскому психологу',
          description: 'сотрудник отдела заботы подберет удобное&nbsp;время',
          button: 'оставить заявку',
          className: 'blue',
        };
      }
      else if (this.currentModal === MODAL_TYPES.demo) {
        modalData = {
          title: 'получите доступ к&nbsp;платформе на&nbsp;7&nbsp;дней',
          cheap: 'бесплатно',
          description: null,
          button: 'перейти на платформу',
          className: 'orange demo',
          register: true,
        };
      }
      else if (this.currentModal === MODAL_TYPES.skills) {
        modalData = {
          title: 'узнать больше о&nbsp;текущих курсах и&nbsp;программах',
        };
      }
      else if (this.currentModal === MODAL_TYPES.skillsGreen) {
        modalData = {
          title: 'узнать больше о&nbsp;текущих курсах и&nbsp;программах',
          className: 'green',
        };
      }
      else if (this.currentModal === MODAL_TYPES.modal14) {
        modalData = {
          title: 'оставьте заявку и&nbsp;узнайте о&nbsp;текущих программах',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.soft) {
        modalData = {
          title: 'оставьте заявку на&nbsp;курсы по&nbsp;развитию soft&nbsp;skills',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и&nbsp;текущих предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.triz) {
        modalData = {
          title: 'запишитесь на&nbsp;<span>бесплатный</span> урок по&nbsp;изобретательству',
          cheap: '5-11 лет',
          description: 'развиваем смекалку и творческое мышление у детей',
          button: 'записаться на урок',
          className: 'violet triz',
        };
      }
      else if (this.currentModal === MODAL_TYPES.preschooler) {
        modalData = {
          title: 'оставьте заявку и&nbsp;узнайте о&nbsp;текущих программах',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.secondDiploma) {
        modalData = {
          title: 'получите диплом&nbsp;гос. и&nbsp;международного образца',
          className: 'red',
        };
      }
      else if (this.currentModal === MODAL_TYPES.secondEducation) {
        modalData = {
          title: 'поможем вам&nbsp;поступить в&nbsp;колледж или вуз мечты',
          className: 'red',
        };
      }
      else if (this.currentModal === MODAL_TYPES.course) {
        modalData = {
          title: 'узнать больше о&nbsp;текущих курсах и&nbsp;программах',
          className: 'red',
        };
      }
      else if (this.currentModal === MODAL_TYPES.college) {
        modalData = {
          title: 'поможем вам&nbsp;поступить в&nbsp;колледж мечты',
          className: 'red',
        };
      }
      else if (this.currentModal === MODAL_TYPES.vuz) {
        modalData = {
          title: 'поможем вам&nbsp;поступить в&nbsp;университет&nbsp;мечты',
          className: 'red',
        };
      }
      else if (this.currentModal === MODAL_TYPES.discount30) {
        modalData = {
          title: 'всем ученикам скидка на&nbsp;поступление',
          cheap: '-30%',
          description: 'оставьте заявку и отдел заботы расскажет подробнее',
          className: 'red discount30',
        };
      }
      else if (this.currentModal === MODAL_TYPES.synergyBox) {
        modalData = {
          title: 'узнать больше о&nbsp;synergy box',
          description: null,
        };
      }
      else if (this.currentModal === MODAL_TYPES.synergyBoxGreen) {
        modalData = {
          title: 'узнать больше о&nbsp;synergy box',
          description: null,
          className: 'green',
        };
      }
      else if (this.currentModal === MODAL_TYPES.suitableTraining) {
        modalData = {
          title: 'мы поможем подобрать подходящее обучение',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и предложениях',
          className: 'pink',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.menuConsult) {
        modalData = {
          title: 'мы поможем подобрать подходящее обучение',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и предложениях',
          button: 'получить консультацию',
        };
      }
      else if (this.currentModal === MODAL_TYPES.languages) {
        modalData = {
          title: 'оставьте заявку на курсы по&nbsp;иностранным языкам',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и&nbsp;текущих предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.transfer) {
        modalData = {
          title: 'планируете перевод из&nbsp;другой школы и&nbsp;вам нужна помощь?',
          description: 'оставьте заявку и сотрудник отдела заботы расскажет все&nbsp;подробности',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.proftest) {
        modalData = {
          title: 'зарегистрируйтесь для&nbsp;сохранения результатов теста',
          description: null,
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.plan) {
        modalData = {
          title: 'скачайте учебный план',
          description: 'оставьте заявку и посмотрите актуальное расписание занятий',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.checkList) {
        modalData = {
          title: 'скачайте чек-лист по&nbsp;переходу',
          description: 'оставьте заявку и получите чек-лист по&nbsp;переходу в&nbsp;онлайн‑школу',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.fizmatClass) {
        modalData = {
          title: 'оставьте заявку, чтобы записаться в физмат класс',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях обучения в профильном классе',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.languageClass) {
        modalData = {
          title: 'оставьте заявку, чтобы&nbsp;записаться в&nbsp;лингвистический класс',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях обучения в профильном классе',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.gymClass) {
        modalData = {
          title: 'оставьте заявку, чтобы записаться в гуманитарный класс',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях обучения в профильном классе',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.econClass) {
        modalData = {
          title: 'оставьте заявку, чтобы записаться в соц. эконом класс',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях обучения в профильном классе',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.profClassConsult) {
        modalData = {
          title: 'оставьте заявку на&nbsp;консультацию',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'оставьте заявку&nbsp;отделу заботы&nbsp;— и мы&nbsp;ответим на&nbsp;любые ваши вопросы',
          button: 'записаться на консультацию',
        };
      }
      else if (this.currentModal === MODAL_TYPES.socGroups) {
        modalData = {
          title: 'оставьте заявку на&nbsp;скидку для социальных групп',
          // eslint-disable-next-line max-len,vue/max-len
          description: 'с&nbsp;вами свяжется отдел заботы и&nbsp;подробно расскажет о&nbsp;всех&nbsp;условиях и&nbsp;текущих предложениях',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.externalBanner) {
        modalData = {
          title: 'оставьте заявку и&nbsp;зафиксируйте за&nbsp;собой скидку на&nbsp;экстернат',
          cheap: '-60%',
          description: 'сотрудник отдела заботы расскажет все&nbsp;подробности',
          button: 'оставить заявку',
          className: 'orange external-banner',
        };
      }
      else if (this.currentModal === MODAL_TYPES.comfort) {
        modalData = {
          title: 'поступайте на&nbsp;«комфорт»<br>в&nbsp;домашнюю школу',
          // eslint-disable-next-line max-len,vue/max-len
          description: null,
          button: 'подать заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.premium) {
        modalData = {
          title: 'поступайте на&nbsp;«премиум»<br>в&nbsp;домашнюю школу',
          // eslint-disable-next-line max-len,vue/max-len
          description: null,
          button: 'подать заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.specialConditions) {
        modalData = {
          title: 'узнайте подробнее о&nbsp;специальных условиях',
          description: 'наш менеджер проконсультирует вас в ближайшее время',
          button: 'подать заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.demo30) {
        modalData = {
          title: 'получить <span>бесплатный</span> доступ к занятиям',
          description: 'оставьте свои данные для&nbsp;регистрации на&nbsp;платформе',
          button: 'открыть уроки',
          register: true,
          goLkIfLloggedIn: true,
        };
      }
      else if (this.currentModal === MODAL_TYPES.uchPlan) {
        modalData = {
          title: 'посмотреть расписание занятий на платформе',
          description: 'оставьте заявку и посмотрите актуальное расписание занятий',
          button: 'перейти на платформу',
          className: 'blue',
          register: true,
        };
      }
      else if (this.currentModal === MODAL_TYPES.externalWithPreparation) {
        modalData = {
          title: 'поступайте на экстернат с подготовкой',
          description: '',
          button: 'подать заявку',
          className: 'orange',
          // register: true,
        };
      }
      else if (this.currentModal === MODAL_TYPES.webinarSignUp) {
        modalData = {
          title: 'записаться на&nbsp;курс',
          description: '',
          button: 'зарегистрироваться',
          className: 'orange webinar',
          // register: true,
          cheap: 'бесплатно',
        };
      }
      else if (this.currentModal === MODAL_TYPES.freeConsult) {
        modalData = {
          title: 'запишитесь на <span>бесплатную</span> консультацию',
          button: 'оставить заявку',
        };
      }
      else if (this.currentModal === MODAL_TYPES.moreProfil) {
        modalData = {
          title: 'узнать больше о&nbsp;домашней школе и&nbsp;профильных программ',
          button: 'оставить заявку',
        };
      }
      else {
        // eslint-disable-next-line no-console
        console.error('Не заданы данные для этой модалки');
      }

      // часто используемые значения ставим по умолчанию
      return {
        description: 'сотрудник отдела заботы ответит на&nbsp;любые вопросы',
        button: 'узнать подробнее',
        className: 'orange',
        ...modalData,
      };
    },
    /* eslint-enable */
    titleToSend() {
      return this.getModalData.title.replace(/<\/?[^>]+(>|$)/g, '');
    },
    commentsToSend() {
      if (this.currentModalComment) {
        return this.currentModalComment;
      }
      if (this.freeTutorsLeadModal) {
        return 'Попробуйте любого репетитора за 0 рублей';
      }
      return '';
    },
    componentLandCode() {
      if (this.currentModal === MODAL_TYPES.externalBanner) {
        return 'new_online_school_externat_60_banner';
      }
      return this.propLandCode;
    },
  },

  methods: {
    ...mapActions('appHeaderModals', [
      'closeModal',
    ]),
    async onSubmit(response) {
      if (this.getModalData.register && this.currentPurchaseProductId) {
        await this.$api.purchaseProduct({ productId: this.currentPurchaseProductId });
      }

      if (this.getModalData.register) {
        localStorage.setItem('REGISTER_PAGE', this.$route.name);
        localStorage.setItem('REGISTER_USER_ID', response?.user?.id);
        window.location = '/classroom';
        return;
      }

      if (this.propLandCode) {
        this.$emit('onSubmit');

        const tg = 'https://salebot.site/418644cd290a7f865895656f2819427a_1';
        if (window.top) {
          window.top.location.href = tg;
        } else {
          window.open(tg, '_parent');
        }

        return;
      }

      if (this.currentModal === MODAL_TYPES.proftest) {
        this.$router.push(getQuestionnairePath({ path: 'proforientacia' }));
      } else {
        this.$router.push(getQuestionnairePath(this.$route));
      }

      this.closeModal();
    },
    onRegisterSubmitError() {
      if (this.getModalData.goLkIfLloggedIn) {
        this.$store.commit('loginEmail', this.emailInput);
        this.$emit('signin');
        this.closeModal();
      } else {
        this.submit();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.header-modal {
  position: fixed;
  z-index: 99;
  inset: 0;
  display: grid;
  place-content: center;

  * {
    font-family: "Onest", sans-serif;
  }

  &__inner {
    position: relative;
    z-index: 2;
    width: calc(var(--scale) * 720);
    max-height: 98vh;
    overflow: auto;
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 40);
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 60);

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 366);
      padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
      border-radius: calc(var(--scale) * 32);
    }
  }

  &__title {
    position: relative;

    h6 {
      position: relative;
      z-index: 2;
      font-weight: 500;
      font-size: calc(var(--scale) * 48);
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 28);
        line-height: 95%;
        padding-inline: calc(var(--scale) * 8);
      }
    }

    p {
      position: absolute;
      padding: calc(var(--scale) * 12) calc(var(--scale) * 20) calc(var(--scale) * 13);
      border-radius: calc(var(--scale) * 40);
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 4) calc(var(--scale) * 10) calc(var(--scale) * 6);
        font-size: calc(var(--scale) * 14);
        line-height: 130%;
      }
    }
  }

  &__description {
    margin-top: calc(var(--scale) * 12);

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 8);
      font-size: calc(var(--scale) * 16);
      padding-inline: calc(var(--scale) * 8);
    }
  }

  &__fields {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 8);
    margin-block: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      padding-inline: calc(var(--scale) * 8);
    }

    .form__input {
      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        height: calc(var(--scale) * 80);
        background-color: #FFFFFF;
        border: 1px solid rgba(167, 171, 172, 0.5);

        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: $black-color;

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 68);
          font-size: calc(var(--scale) * 18);
        }

        &::placeholder {
          color: #A7ABAC;
        }
      }

      ::v-deep .phone-field__input {
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: $black-color;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 18);
        }

        &::placeholder {
          color: #A7ABAC;
        }
      }

      ::v-deep .phone-field__wrap {
        height: calc(var(--scale) * 80);
        background-color: #FFFFFF;
        border: 1px solid rgba(167, 171, 172, 0.5);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 68);
        }

        input {
          border-radius: inherit;

          &::placeholder {
            color: #A7ABAC;
          }
        }

        &--error {
          border-color: #ff0040;
        }
      }
    }
  }

  &__law {
    margin-bottom: calc(var(--scale) * 40);

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      font-size: calc(var(--scale) * 14);
    }

    a {
      text-decoration: underline;
      color: #FF9C12;

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__btn {
    width: 100%;
    padding: calc(var(--scale) * 36) calc(var(--scale) * 10);
    border-radius: calc(var(--scale) * 24);
    transition: 200ms;

    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 30) 0;
      font-size: calc(var(--scale) * 18);
    }
  }

  &__close {
    position: absolute;
    z-index: 2;
    right: calc(var(--scale) * 40);
    top: calc(var(--scale) * 30);
    width: calc(var(--scale) * 44);

    @include media-down($size-tablet) {
      right: calc(var(--scale) * 20);
      top: calc(var(--scale) * 15);
    }
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(#02120F, 0.3);
    backdrop-filter: blur(5px);
    cursor: pointer;
  }

  // color variants
  &.orange {
    .header-modal__title {
      p {
        background-color: #FFF0D1;
        color: #FF5319;
      }

      ::v-deep(span) {
        color: #FF5319;
      }
    }

    .header-modal__btn {
      background-color: #FF5319;

      @media (hover: hover) {
        &:hover {
          background-color: #FF9C12;
        }
      }
    }
  }

  &.green {
    .header-modal__title ::v-deep(span) {
      color: #22AE9D;
    }

    .header-modal__btn {
      background-color: #01412E;

      @media (hover: hover) {
        &:hover {
          background-color: #22AE73;
        }
      }
    }
  }

  &.violet {
    .header-modal__title {
      p {
        background-color: #E6CEFF;
        color: #5237E6;
      }

      ::v-deep(span) {
        color: #5237E6;
      }
    }

    .header-modal__btn {
      background-color: #5237E6;

      @media (hover: hover) {
        &:hover {
          background-color: #846EFB;
        }
      }
    }
  }

  &.pink  {
    .header-modal__btn {
      background-color: #8D176C;

      @media (hover: hover) {
        &:hover {
          background-color: #F199CE;
        }
      }
    }
  }

  &.blue {
    .header-modal__btn {
      background-color: #0A4183;

      @media (hover: hover) {
        &:hover {
          background-color: #0B72BE;
        }
      }
    }
  }

  &.red {
    .header-modal__title {
      p {
        color: #8D1717;
        background-color: #FFCDCF;
      }
    }

    .header-modal__law a {
      color: #ED131C;
    }

    .header-modal__btn {
      background-color: #ED131C;

      @media (hover: hover) {
        &:hover {
          background-color: #FFCDCF;
        }
      }
    }
  }

  &.demo {
    .header-modal__title {
      h6 {
        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 40);
        }
      }

      p {
        right: calc(var(--scale) * 62);
        top: calc(var(--scale) * 8);
        transform: rotate(9deg);

        @include media-down($size-tablet) {
          top: auto;
          bottom: calc(var(--scale) * 4);
          right: calc(var(--scale) * 66);
          transform: rotate(-7deg);
        }
      }
    }
  }

  &.triz {
    .header-modal__title p {
      right: calc(var(--scale) * 40);
      bottom: calc(-1 * var(--scale) * 66);
      transform: rotate(9deg);

      @include media-down($size-tablet) {
        transform: rotate(7deg);
        top: calc(var(--scale) * 6);
        right: calc(var(--scale) * 6);
        bottom: auto;
      }
    }
  }

  &.external-banner {
    .header-modal__title p {
      padding: calc(var(--scale) * 4) calc(var(--scale) * 14);
      position: absolute;
      right: 0;
      top: 0;
      background-color: #5237E6;
      border-radius: calc(var(--scale) * 100);
      transform: rotate(15deg);

      font-weight: 700;
      font-size: calc(var(--scale) * 32);
      line-height: 130%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        top: calc(-1 * var(--scale) * 6);
        padding: calc(var(--scale) * 2) calc(var(--scale) * 7);
        font-size: calc(var(--scale) * 16);
      }
    }
  }

  &.discount30 {
    .header-modal__title p {
      right: calc(var(--scale) * 180);
      bottom: 0;
      transform: rotate(9deg);

      @include media-down($size-tablet) {
        transform: rotate(5deg);
        right: calc(var(--scale) * 85);
        bottom: calc(-1 * var(--scale) * 4);
      }
    }
  }

  &.webinar {
    h6 {
      max-width: calc(var(--scale) * 600);

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 40);
      }
    }

    .header-modal__title p {
      right: calc(var(--scale) * 30);
      top: calc(var(--scale) * 4);
      transform: rotate(5deg);

      @include media-down($size-tablet) {
        top: auto;
        bottom: calc(var(--scale) * 2);
        right: calc(var(--scale) * 110);
        transform: rotate(-5deg);
      }
    }
  }
}
</style>
